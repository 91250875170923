
define('template!headerViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="header-left caccount-hover-dropdown dropdown">\n    <div class="js-caccount clickable">\n        <img alt="logo caccount" class="header-logo-left js-caccount-logo" src="' +
((__t = ( logo )) == null ? '' : __t) +
'" style="max-height: 40px;">\n        <span class="js-caccount-code" title="' +
((__t = ( title )) == null ? '' : __t) +
'">' +
((__t = ( code )) == null ? '' : __t) +
'</span>\n        <span class="js-caret-caccount mdi mdi-menu-down"></span>\n    </div>\n    <div class="dropdown-menu caccounts-dropdown-container js-caccounts-autocomplete"></div>\n</div>\n\n<div class="header-center" style="padding-left: 240px;">\n    <div class="header-left" style="justify-content: flex-end;">\n        <img alt="logo mobiolink" class="compuzz_general_logo" src="' +
((__t = ( folder )) == null ? '' : __t) +
'images/logo.svg">\n    </div>\n    <div class="header-center">\n        <img alt="logo mobiolink" class="compuzz_general_title" src="' +
((__t = ( folder )) == null ? '' : __t) +
'images/mobiolink.svg">\n    </div>\n    <div class="header-right" style="justify-content: flex-start; margin-top: 20px;">\n        ';
 if(label) { ;
__p += '\n        <span class="label ' +
((__t = ( label )) == null ? '' : __t) +
'">' +
((__t = ( label )) == null ? '' : __t) +
'</span>\n        ';
 } ;
__p += '\n    </div>\n</div>\n\n<div class="header-right">\n    <div class="dropdown js-user ' +
((__t = ( loginTo ? 'loginTo': '' )) == null ? '' : __t) +
'" title="' +
((__t = ( userNameTitle )) == null ? '' : __t) +
'">\n        <div aria-expanded="false" aria-haspopup="true" class="dropdown-toggle clickable" data-toggle="dropdown" id="user-information">\n            <div class="avatar-in-header">\n                <img alt="avatar" src="' +
((__t = ( avatar )) == null ? '' : __t) +
'">\n            </div>\n            <span class="js-user-name">' +
((__t = ( userName )) == null ? '' : __t) +
'</span>\n            <span class="js-caret-user mdi mdi-menu-down"></span>\n        </div>\n        <ul aria-labelledby="user-information" class="dropdown-menu">\n            <li class="js-edit-login clickable">\n                <span class="mdi mdi-pencil-box-outline"></span>\n                <a class="">' +
((__t = ( _.i18n('common.edit') )) == null ? '' : __t) +
'</a>\n            </li>\n            <li class="js-languages clickable">\n                <span class="mdi mdi-flag"></span>\n                <a class="">' +
((__t = ( _.i18n('common.languages') )) == null ? '' : __t) +
'</a>\n            </li>\n            <li class="js-authorities clickable">\n                <span class="mdi mdi-security"></span>\n                <a class="">' +
((__t = ( _.i18n('authorities.show') )) == null ? '' : __t) +
'</a>\n            </li>\n            <hr/>\n            <li class="logout-user clickable">\n            ';
 if(loginTo) { ;
__p += '\n                <span class="mdi mdi-account-switch"></span>\n                <a>' +
((__t = ( _.i18n('common.logout.user') )) == null ? '' : __t) +
'</a>\n            ';
 } else { ;
__p += '\n                <span class="mdi mdi-logout"></span>\n                <a>' +
((__t = ( _.i18n('common.logout') )) == null ? '' : __t) +
'</a>\n            ';
 } ;
__p += '\n            </li>\n            ';
 if(superUser) { ;
__p += '\n            <hr/>\n            <li class="js-godmode clickable">\n                <span class="mdi mdi-wrench-cog"></span>\n                <a class="">' +
((__t = ( _.i18n('menu.superadmin.godMode') )) == null ? '' : __t) +
'</a>\n            </li>\n            ';
 } ;
__p += '\n        </ul>\n    </div>\n</div>\n';

}
return __p
};});

